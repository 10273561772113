// Generated by gulp-iconfont 
// Based on https://github.com/endtwist/fontcustom/blob/master/lib/fontcustom/templates/fontcustom.css
// http://jaydenseric.com/blog/font-icons-like-a-boss-with-sass-and-font-custom 


// Declare icon font
@include font-face('iconfont', '../fonts/iconfont', $file-formats: eot woff ttf svg);


// List icons

$icons: (
    
        arrow-left: "\EA01",
        arrow-right: "\EA02",
        caret-down: "\EA03",
        caret-up: "\EA04", 
);

// Use to apply icons
@mixin icon($position: before, $icon: false, $styles: true) {
    @if $position == both {
        $position: 'before, &:after';
    }
    &:#{$position} {
        @if $icon {
            content: "#{map-get($icons, $icon)}";
        }
        // Either a :before or :after pseudo-element, or both, defaulting to :before
        @if $styles {
            font-family: 'iconfont';
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            text-rendering:optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            display: inline-block;
        }
        @content;
    }
}

// Set the required styles on all icons
[class^="icon-"],
[class*=" icon-"] {
    @include icon;
}

// Setup a class name for each icon
@each $name, $char in $icons {
    .icon-#{$name} {
        @include icon(before, $name, false);
        // content: $char;
    }
}