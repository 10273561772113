//------------------------
// LAYOUT template CMSMS
//------------------------

.col-primary,
.col-secondary {
	@include xy-cell(12);
}

@include breakpoint(large) {
	// contenu + colonne à gauche par défaut
	.layout-two-col-left {
		.col-primary {
			@include xy-cell(8);
			order: 2;
		}
		.col-secondary {
			@include xy-cell(4);
			order: 1;
		}
	}
	// Pas de colonnes (contenu seul)
	.layout-full-width {
		.col-primary {@include xy-cell(12);}
	}
	// Contenu + colonne à droite
	.layout-two-col-right {
		.col-primary {@include xy-cell(8);}
		.col-secondary {@include xy-cell(4);}
	}
}



//------------------------
// FORMULAIRES CMSMS
//------------------------
form {
	.hide-labels {// masque les <label> (ajouter la classe dans le champ de Formbuilder)
		label {@include element-invisible;}
		.required {
			position: relative;
			&:after {
				content: "*";
				color: $alert-color;
				position: absolute;
				right: 10px;
				top: 12px;
			}
		}
		fieldset {
			.required:after  {
				right: 25px;
			}
			&.row {
				margin: $fieldset-margin;
				padding: $fieldset-padding ($fieldset-padding/2)
			}
			legend {

			}
		}
	}

	.cms_submit,
	#saveForm {// bouton d'envoi Formbuilder
		@include button();
	}

	.mention {// Mention type "champs obligatoires"
		font-style: italic;
		font-size: rem-calc(12);
	}

	// alignement de la mention et du bouton
	.mention,
	.submit {
		@include grid-column(12);
	}
	.fbsubmit {
		width: 100%;
	}
	@include breakpoint(medium) {
		.mention,
		.submit {
			@include grid-column(6);
		}
	}
}

#{text-inputs()} {// We use this to get basic styling on all basic form elements
	.fb_invalid & {// mise en surbrillance des champs invalides à la soumission
		background-color: scale-color($alert-color, $lightness: 85%);
		border-color: $alert-color;
	}
	&:focus:invalid {// mise en surbrillance du champ si invalid et focus (avant soumission)
		color: $alert-color;
	}
}

#tinymce {
	background-color: #fff!important;// Fond blanc pour l'éditeur Tinymce
	padding: 10px!important;
	a {
		text-decoration: underline;
	}
	@if $global-font-size == 100% {
        font-size: 16px;
    }
    @else {
        font-size: $global-font-size;
    }
}
