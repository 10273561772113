@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
%vertical-align {
  @include vertical-align()
}


@mixin vertical-rhythm($font-size, $top-rows: 0, $bottom-rows: 1, $use-height: false, $border: 0rem) {
  // I use a rem function that enables me to pass in the $font-size and $border
  // as rems or px, and the result of the function will give me rems
  $line-height-base: 1rem * $global-lineheight;
  $font-size: $font-size;
  $border: rem-calc($border);
  $multiplier: ceil($font-size / $line-height-base + rem-calc(10));

  // check if height-based element
  // if so use height property for height, otherwise use line-height property
  @if $use-height {
    $leftover:      $line-height-base * $multiplier - $font-size;
    height:         $font-size;
    margin-top:     $line-height-base * $top-rows + $leftover / 2;
    margin-bottom:  $line-height-base * $bottom-rows + $leftover / 2;
  }
  @else { // text based element (uses line-height)
    font-size:      $font-size;
    line-height:    $multiplier;
    margin-top:     $line-height-base * $top-rows;
    margin-bottom:  $line-height-base * $bottom-rows - $border;
  }
}

@mixin retina-image($filename, $background-size, $extension: 'png') {
  background-image: url($filename + '.' + $extension);
  @include breakpoint(retina) {
    & {
      background-image: url($filename + '_2x.' + $extension);
      background-size: $background-size;
    }
  }
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}


//-------------------------
//  FONT FACE (bourbon)
//  -----------------------
@mixin font-face(
  $font-family,
  $file-path,
  $file-formats: ("eot", "woff", "ttf", "svg")
) {
  @font-face {
    font-family: $font-family;
    src: _font-source-declaration(
      $font-family,
      $file-path,
      $file-formats
    );
    @content;
  }
}
@function _font-source-declaration(
  $font-family,
  $file-path,
  $file-formats
) {
  $src: ();

  $formats-map: (
    eot:   "#{$file-path}.eot?#iefix" format("embedded-opentype"),
    woff2: "#{$file-path}.woff2" format("woff2"),
    woff:  "#{$file-path}.woff" format("woff"),
    ttf:   "#{$file-path}.ttf" format("truetype"),
    svg:   "#{$file-path}.svg##{$font-family}" format("svg"),
  );

  @each $key, $values in $formats-map {
    @if _contains($file-formats, $key) {
      $file-path: nth($values, 1);
      $font-format: nth($values, 2);
      $src: append($src, url($file-path) $font-format, comma);

    }
  }

  @return $src;
}
@function _contains(
  $list,
  $values...
) {
  @each $value in $values {
    @if type-of(index($list, $value)) != "number" {
      @return false;
    }
  }

  @return true;
}



@mixin reset-container-margin($extra-padding: true) {
  // Body padding
  margin-left: -($global-padding / 2);
  margin-right: -($global-padding / 2);
  @if $extra-padding {
    padding-left: $global-padding / 2;
    padding-right: $global-padding / 2;
  }


  @include breakpoint(large) {
    // Body padding
    margin-left: -($global-padding * 2);
    margin-right: -($global-padding * 2);
    @if $extra-padding {
      padding-left: ($global-padding * 3);
      padding-right: ($global-padding * 3);
    }
  }

  @include breakpoint(xlarge) {
    // Body padding
    margin-left: -($global-padding * 3);
    margin-right: -($global-padding * 3);
    @if $extra-padding {
      padding-left: ($global-padding * 4);
      padding-right: ($global-padding * 4);
    }
  }

  @include breakpoint(xxlarge) {
    // Body padding
    margin-left: -($global-padding * 4);
    margin-right: -($global-padding * 4);
    @if $extra-padding {
      padding-left: ($global-padding * 5);
      padding-right: ($global-padding * 5);
    }
  }
}
