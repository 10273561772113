@charset 'utf-8';
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@800&family=Mukta:wght@400;700;800&display=swap');

$imagesPath: '../images';
$slick-loader-path: $imagesPath;

@import 'base/app';
@import 'base/mixins';

@import 'base/icons';
@import 'base/common';

@import 'vendor/aos';
@import 'vendor/slick-carousel';
@import 'vendor/cookies-eu-banner';
@import 'vendor/tablesaw';

// Fonctions pour pouvoir importer jquery.fancy.css
@function max($numbers...){
  @return m#{a}x(#{$numbers});
}

@function min($numbers...){
  @return m#{i}n(#{$numbers});
}

@import 'vendor/jquery.fancybox';

// CMS Specifics
@import "base/cmsms";

// -----------------
// PREVENT FOUC
// -----------------
.no-js {
  @include breakpoint(medium down) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(large) {
    .title-bar {
      display: none;
    }
  }
}

// -----------------
// GENERAL
// -----------------
html, body {
  height: 100%;
}

body {
  position: relative;
  overflow-x: hidden;
}

@include breakpoint(small only) {
  p, div {
    font-size: rem-calc(15);
  }
}

// -----------------
// HEADER
// -----------------
.page-header {
  background-color: $primary-color;
  position: relative;
  padding: 15px 0;

  @include breakpoint(large) {
    padding: 18px 0;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("#{$imagesPath}/logotype.svg") no-repeat -90% -100px;
    background-size: 85% auto;
    opacity: .2;

    @include breakpoint(small only) {
      background-position: -55% -20px;
    }

    @include breakpoint(medium only) {
      background-size: 60% auto;
      background-position: -30% -30px;
    }
  }

  .grid-container{
    position: relative;
    z-index: 3;
  }

  .cell{
    @include breakpoint(small only) {
      padding: 0 15px;
    }

    &.nav-cell{
      @include breakpoint(large) {
        padding-left: 0;
      }
    }
  }

  &__nav {
    .top-bar{
      @include breakpoint(medium down) {
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        background-color: $primary-color;

        .main-menu{
          width: 100%;
        }
      }
    }

    .menu {
      li{
        .item {
          position: relative;
          letter-spacing: 1px;
          opacity: .54;
          font-size: rem-calc(16);
          line-height: rem-calc(21);
          text-align: center;

          @include breakpoint(xlarge) {
            font-size: rem-calc(18);
          }

          &.item-niv-1{
            color: $white;
          }

          &:hover{
            opacity: 1;
          }
        }

        &.active{
          > .item {
            opacity: 1;
          }
        }
      }

      > li{
        border-bottom: 1px solid rgba($white, 0.1);
        padding-bottom: 0;

        @include breakpoint(large){
          position: relative;
          border-bottom: none;

          &:before {
            content: '';
            position: absolute;
            background: $fourth-color;
            width: 98%;
            height: 1px;
            bottom: 0;
            left: 1%;
            transform: scale3d(0,1,1);
            opacity: 0;
            transition: all 0.3s;
            transition-property: opacity, transform;
          }


          &.active, &:hover{
            &:before {
              opacity: 1;
              transform: scale3d(1,1,1);
            }
          }
        }

        .item{
          @include breakpoint(large only) {
            padding: 10px 20px;
          }
        }
      }

      .submenu{
        list-style: none;
        margin-left: 0;
        background-color: $white;
        border: none;
        padding: 10px 0;
        box-shadow: -5px 5px 10px rgba($black, 0.1);

        li{
          .item{
            padding: 10px 20px;
            font-size: rem-calc(17);

            @include breakpoint(large down) {
              font-size: rem-calc(15);
            }

            @include breakpoint(large) {
              text-align: left;
            }
          }

          &.active{
            .item{
              color: $black;
            }
          }
        }
      }
    }

    .title-bar{
      .menu-icon{
        width: 30px;
        height: 22px;

        &:after{
          display: none;
        }

        span{
					display: block;
					position: absolute;
					height: 2px;
					width: 100%;
					background-color: $white;
					right: 0;
					transform: rotate(0deg);
					transition: all 250ms ease-in-out;

					&:first-child{
						top: 0;
					}

					&:nth-child(2){
						top: calc(50% - 1px);
					}

					&:last-child{
						bottom: 0;
					}
				}

        &.opened{
					span{
						&:first-child{
							transform: rotate(45deg);
							top: 10px;
						}

						&:nth-child(2){
							width: 0;
						}

						&:last-child{
							bottom: 10px;
							transform: rotate(-45deg);
						}
					}
				}
      }
    }

    &.lang-menu{
      margin-top: -5px;
      margin-bottom: -8px;

      @include breakpoint(large) {
        margin-left: 10px;
      }

      .inline-list{
        > li{
          border-color: transparent;
          margin-right: 20px;

          &.active{
            border-color: $fourth-color;
          }
        }

        .item{
          padding: 0 0 3px 0;
          font-size: 0.75rem;

          @include breakpoint(large) {
            font-size: 0.85rem;
          }
        }
      }
    }
  }

  &__logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .logo {
      transition: all ease 250ms;
      width: 250px;
    }

    .sitename {
      color: $white;
      margin-left: 20px;
      display: inline-block;
      position: relative;
      padding-bottom: 10px;
      padding-right: 20px;
      font-size: rem-calc(16);
      text-align: left;

      @include breakpoint(xlarge) {
        font-size: rem-calc(18);
      }

      &:after {
        content: '';
        width: 100%;
        position: absolute;
        height: 1px;
        background-color: $fourth-color;
        bottom: 0;
        left: 0;
      }

      .name{
        text-transform: uppercase;
        font-weight: 800;
        font-family: 'Assistant', sans-serif;
      }

      .motto{
        display: block;
        font-size: rem-calc(12);
        letter-spacing: 0.05em;
      }
    }
  }
}

// -----------------
// FOOTER
// -----------------
.page-footer {
  color: $white;
  background-color: $primary-color;
  text-align: center;

  @include breakpoint(medium) {
    text-align: left;
  }

  a{
    color: $white;
  }

  &__top{
    .grid-container{
      padding: 30px 20px;
      position: relative;

      @include breakpoint(large) {
        padding: 40px 0 30px;
      }

      &:after{
        content: '';
        position: absolute;
        width: calc(100% - 40px);
        left: 20px;
        bottom: 0;
        height: 1px;
        background-color: $fourth-color;
        opacity: 0.5;
      }
    }

    .footer-logo{
      .footer__logo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include breakpoint(large) {
          flex-direction: row;
          justify-content: flex-start;
        }

        img{
          max-width: 150px;
          margin-bottom: 10px;
          @include breakpoint(medium){
            max-width: 200px;
            margin-bottom: 0;
          }
          @include breakpoint(large){
            max-width: 250px;
          }
        }

        .brand{
          margin: 15px 0 10px;

          @include breakpoint(medium) {
            margin-bottom: 0;
          }

          @include breakpoint(large) {
            margin-top: 0;
            margin-left: 25px;
          }

          .sitename{
            font-size: 1.05rem;
            text-transform: uppercase;
            font-weight: 800;
            font-family: 'Assistant', sans-serif;
          }

          .motto{
            font-size: rem-calc(14);
            letter-spacing: 0.05em;

            @include breakpoint(large) {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .footer-contact{
      font-size: rem-calc(14);
      line-height: 22px;
      color: $white;

      @include breakpoint(large) {
        font-size: 1rem;
      }

      a{
        transition: all 250ms ease;

        &:hover{
          opacity: 0.7;
        }
      }

      .icon-phone, .icon-email{
        margin-bottom: 0;
      }

      .title{
        color: $white;
        margin-top: 26px;

        @include breakpoint(large) {
          margin-top: 0;
        }
      }

      .text{
        font-size: rem-calc(14);
      }

      .button{
        margin-bottom: 0;
      }
    }
  }

  &__bottom{
    padding: 15px 0;

    @include breakpoint(large) {
      padding: 20px 0 30px;
    }

    .footer-nav{
      margin-bottom: 5px;

      @include breakpoint(medium) {
        margin-bottom: 0;
      }

      &__list{
        li{
          padding-bottom: 5px;

          a{
            font-size: rem-calc(13);
            transition: all 250ms ease;
            padding: 5px;

            @include breakpoint(medium) {
              padding: 5px 10px;
            }

            &:hover{
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

// ----------------------------
// HOME
// ----------------------------
.hero {
  position: relative;
  margin-bottom: -5px;

  .slider-nav {
    position: absolute;
    bottom: 5px;
    right: 0;
    font-size: 70%;
    background-color: $white;
    display: flex;
    align-items: center;
    padding: 9px 8px;
    box-shadow: 0 -2px 5px rgba($primary-color, 0.2);
    z-index: 1;

    @include breakpoint(medium) {
      bottom: 7px;
    }
  }

  .slider-count {
    padding-right: 15px;
  }

  .slider-arrows {
    .slick-prev {
      @include icon(after, 'arrow-left');
      padding-right: 15px;
    }

    .slick-next {
      @include icon(after, 'arrow-right');
    }

    .slick-arrow {
      text-indent: -9999px;
      position: relative;
      cursor: pointer;
      line-height: 0;
      vertical-align: middle;

      &:after {
        display: block;
        color: $black;
        text-indent: 0;
        font-size: rem-calc(24);
        transition: all 250ms ease;
      }

      &:hover{
        &:after{
          opacity: 0.5;
        }
      }
    }
  }
}

.page-home, .page-accueil {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("#{$imagesPath}/logotype.svg") no-repeat -90% -100px;
    background-size: 85% auto;
    opacity: .2;

    @include breakpoint(small only) {
      background-position: -55% -20px;
    }

    @include breakpoint(medium only) {
      background-size: 60% auto;
      background-position: -30% -30px;
    }
  }

  .page-header{
    &:after{
      display: none;
    }
  }

  main{
    .grid-container{
      position: relative;
      z-index: 1;
    }
  }
}

.intro-section{
  position: relative;

  &:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 30%;
    top: 0;
    left: 0;
    background-color: $primary-color;
  }

  @include breakpoint(medium) {
    padding: 20px 0;
  }

  .grid-container{
    @include breakpoint(small only) {
      padding: 0;

      .grid-margin-x, .cell{
        margin: 0;
        width: 100%;
      }
    }
  }

  &__inner{
    justify-content: center;

    @include breakpoint(large) {
      padding-bottom: 30px;
    }

    @include breakpoint(xlarge) {
      padding-bottom: 0;
    }
  }

  .slides{
    background-color: $primary-color;

    @include breakpoint(medium) {
      background-color: transparent;
    }

    @include breakpoint(xlarge) {
      position: relative;
    }
  }
}

.slide{
  &__text{
    background-color: $primary-color;
    color: $white;
    width: 100%;
    padding: 20px;

    @include breakpoint(medium) {
      position: absolute;
      bottom: 7px;
      left: 0;
      width: 60%;
      padding: 25px 30px 10px;
    }

    @include breakpoint(large) {
      width: 40%;
    }

    .readmore{
      color: $white;
    }
  }

  &__title{
    color: white;
    margin-top: 0;
  }
}

.intro-text-section{
  .intro{
    padding: 40px 10px 30px;

    @include breakpoint(large) {
      padding: 45px 0;
    }

    .intro-text{
      .title{
        margin-bottom: 10px;
        font-size: rem-calc(28);

        @include breakpoint(large) {
          margin-bottom: 20px;
          font-size: rem-calc(36);
        }
      }

      .text{
        p{
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
}


// -----------------
// SECTIONS
// -----------------
.pages-section{
  padding: 20px 0 40px;

  @include breakpoint(medium) {
    padding: 30px 0 60px;
  }

  .grid-container{
    position: relative;
    z-index: 1;
  }

  .page-block{
    margin-bottom: 35px;
    padding: 0 10px;

    @include breakpoint(medium) {
      padding: 0;
      margin-bottom: 0;
    }

    &:last-child{
      margin-bottom: 0;
    }

    &__link{
      &:hover{
        transform: translateY(-10px);
      }
    }
  }
}


.news-section{
  background-color: rgba($tertiary-color, 0.3);
  padding: 40px 0;

  @include breakpoint(medium) {
    padding: 60px 0;
  }

  .title{
    margin-top: 0;
    margin-bottom: 30px;

    @include breakpoint(medium) {
      margin-bottom: 35px;
    }

    a{
      transition: all 250ms ease;

      &:hover{
        color: $secondary-color;
      }
    }
  }

  .news{
    &__inner{
      @include breakpoint(medium) {
        padding: 0 10px;
      }
    }
  }

  &.home-news-section{
    .news-list{
      justify-content: center;
    }
  }
}

.contact-section{
  &__img{
    @include breakpoint(medium) {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__text{
    padding: 30px 10px 40px;
    max-width: 600px;

    @include breakpoint(medium) {
      display: flex;
      padding: 30px 45px;
      flex-direction: column;
      justify-content: center;
    }

    @include breakpoint(large) {
      padding: 90px 60px;
    }

    .title{
      margin-top: 0;
      margin-bottom: 20px;
    }

    .button{
      margin-bottom: 0;
      margin-top: 10px;
      margin-right: 20px;

      @include breakpoint(medium) {
        margin-right: 25px;
      }

      &:last-child{
        margin-right: 0;
      }
    }
  }
}

// ----------------------------
// NEWS
// ----------------------------

.news-list{
  padding: 0 10px;

  @include breakpoint(medium) {
    padding: 0;
  }

  .news{
    margin-bottom: 45px;

    a{
      text-decoration: none;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }
}

.news{
  &__title{
    margin-top: 0;
    margin-bottom: 15px;

    @include breakpoint(medium) {
      margin-bottom: 20px;
      margin-top: 5px;
    }

    a{
      transition: all 250ms ease;

      &:hover{
        color: $fourth-color;
      }
    }
  }

  .button{
    margin-bottom: 0;
  }

  &-date{
    margin-bottom: 10px;
  }

  &-footer{
    margin-top: 40px;
  }

  &__img{
    margin-bottom: 20px;

    .news-link{
      position: relative;
      display: block;

      &:before{
        content: '';
        position: absolute;
        background-color: rgba($fourth-color, 0.2);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
        transition: all 250ms ease;
      }

      &:hover{
        &:before{
          opacity: 1;
        }
      }
    }
  }
}


// ----------------------------
// PAGE INTERIEURE
// ----------------------------
.page-int {
  .page-header {
    &.sticky {
      width: auto;
      max-width: none!important;
    }

    &.is-stuck {
      width: 100%;
      box-shadow: 0 0 15px rgba($black, .7);
    }

    @include breakpoint(large) {
      padding-right: 0;
    }
  }
}

.page-content{
  padding: 40px 0;

  @include breakpoint(medium) {
    padding: 60px 0;
  }

  .grid-container >{
    .grid-x{
      > .cell{
        padding: 0 20px;

        @include breakpoint(medium) {
          padding: 0 15px;
        }
      }
    }
  }

  &__blocks{
    margin-top: 14px;
  }

  &__solutions{
    margin: 14px 0;
  }

  &__body{
    margin-top: 14px;

    + .page-content__blocks{
      margin-top: 24px;
    }
  }

  &__column1{
    @include breakpoint(medium) {
      padding-right: 20px !important;
      margin-top: 2rem;
    }

    @include breakpoint(large) {
      padding-right: 25px !important;
    }
  }

  &__column2{
    @include breakpoint(medium) {
      padding-left: 20px !important;
      margin-top: 2rem;
    }

    @include breakpoint(large) {
      padding-left: 25px !important;
    }
  }

  &__under{
    @include breakpoint(medium) {
      margin-top: 2rem;
    }
  }
}

.page-block{
  margin-bottom: 40px;

  .title{
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .text{
    margin-bottom: 15px;
  }

  .button{
    margin-bottom: 0;
  }

  &__img{
    position: relative;

    &:before{
      content: '';
      position: absolute;
      background-color: rgba($fourth-color, 0.2);
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      transition: all 250ms ease;
    }
  }

  &__link{
    display: block;
    transition: all 250ms ease;

    .title{
      margin-top: 15px;
      transition: all 250ms ease;

      @include breakpoint(medium) {
        margin-top: 20px;
      }
    }

    &:hover{
      .title{
        color: $fourth-color;
      }

      .button{
        opacity: 0.8;

        &:after{
          margin-left: 20px;
        }
      }

      .page-block__img{
        &:before{
          opacity: 1;
        }
      }
    }
  }
}

.solution-grid{
  align-items: flex-end;

  .solution{
    margin-bottom: 1rem;

    .solution-inner-grid{
      align-items: flex-end;
    }

    &__text{
      p{
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}

.frise{
  position: relative;
  padding-bottom: 20px;
  margin-top: 2rem;

  @include breakpoint(large) {
    max-width: 800px;
    margin: 2rem auto 1rem;
  }

  &:after{
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: $primary-color;
    left: 0;
    top: 0;

    @include breakpoint(medium) {
      left: 50%;
    }
  }

  &__left, &__right{
    margin-bottom: 20px;
    position: relative;
    padding-left: 15px;

    &:before{
      content: '';
      position: absolute;
      width: 10px;
      height: 1px;
      left: 0;
      top: 10px;
      background-color: $primary-color;
    }

    @include breakpoint(medium) {
      padding-left: 0;
    }

    p{
      margin-bottom: 0;

      &.frise__title{
        margin: 5px 0 8px -15px !important;
      }
    }
  }

  &__left{
    @include breakpoint(medium) {
      text-align: right;
      @include xy-cell(6);

      &:before{
        left: auto;
        right: -15px;
      }

      p.frise__title{
        margin: 5px -15px 8px 0 !important;
      }
    }
  }

  &__right{
    @include breakpoint(medium) {
      @include xy-cell(6);
      margin-left: calc(50% + 15px);

      &:before{
        left: -15px;
      }
    }
  }

  &__title{
    background-color: $primary-color;
    color: $white;
    display: inline-block;
    padding: 5px 15px;
    text-transform: uppercase;
  }
}

// ================================
// PAGE NEWS
// ================================
.page-news{
  .page-content{
    &:not(.news-detail){
      + .news-section{
        display: none;
      }
    }
  }
}


// ================================
// POP UP
// ================================
#fancybox-modal{
  display: none;
  text-align: center;
  @include xy-cell(11);
  padding: 0;

  @include breakpoint(large) {
    @include xy-cell(6);
  }

  a{
    outline: none;
    cursor: pointer;
  }

  .fancybox-img{
    img{
      width: 100%;
    }
  }

  .fancybox-body{
    cursor: pointer;
    padding: 30px;

    .fancybox-title{
      margin-top: 0;
			color: $primary-color;
    }

    .fancybox-text{
      color: $black;
    }
  }

  .fancybox-button{
    background-color: $white;
    opacity: 1;
		height: 40px;
		width: 40px;
		padding: 5px;
  }
}

.back-top{
  position: fixed;
  bottom: 15px;
  right: 15px;
  opacity: 0;
  z-index: 999;
  transition: all 250ms ease-in-out;

  @include breakpoint(large) {
    bottom: 20px;
    right: 20px;
  }

  .button{
    @include icon(after, caret-up);
    width: 40px;
    min-width: 40px;
    height: 40px;
    text-align: center;
    margin-bottom: 0;
    padding: 0;
    line-height: 40px;
  }

  &.visible{
    opacity: 1;
  }
}


// ================================
// PRINT
// ================================
@media print {
  .page-header,
  .page-footer__bottom,
  .slider-nav,
  #main-bottom {
    display: none!important;
  }

  .slick-slide,
  .slick-track {
    width: 100%!important;
  }

  .slick-slide{
    &:not(.slick-current){
      display: none!important;
    }
  }

  .slick-track {
    transform: translate3d(0,0,0)!important;
  }

  .accordion-content {
    display: block!important;
  }

  a[href]:after {
    content: none !important;
  }
}
