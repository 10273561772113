#cookies-eu-banner {
  background: rgba($black,.9);
  color: $white;
  padding: 6px;
  font-size: rem-calc(13);
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 999;
}

#cookies-eu-more,
#cookies-eu-accept,
#cookies-eu-reject {
  text-decoration: none;
  color: $white;

  cursor: pointer;
  font-size: rem-calc(13);
  transition: background 0.07s, color 0.07s, border-color 0.07s;
}
#cookies-eu-accept,
#cookies-eu-reject {
  padding: 4px 7px;
  margin: 2px 0;
  font-weight: bold;
  font-size: rem-calc(14);
}


#cookies-eu-accept {
  @include button-style($success-color,auto,auto);
  padding: 7px;

}

#cookies-eu-more {
  margin-right: 7px;
}

#cookies-eu-reject {
  background: none;
  font-weight: normal;
  cursor: pointer;
  padding: 4px 7px;
  margin: 2px 0;
  border: 1px solid #666;

  &:hover,
  &:focus {
    border-color: $white;
    background: $black;
    color: $white;
  }
}
