//------------------------
// VARIABLES
//------------------------
$logo-rb-version: dark !default;// Fond foncé = light / Fond clair = dark
$include-html-text-color-classes: true !default; // variante de couleurs de texte
$include-html-bg-color-classes: true !default; // variante de couleurs de fonds



//------------------------
// GENERAL
//------------------------
body {
	line-height: $global-lineheight;
}

.chromeframe {
   background: #FDF2AB;
   border-bottom:1px solid #A29330;
   color: #000;
   font-size:12px;
   margin: 0;
   padding: 5px 36px 5px 40px;
   text-align:center;
	a {color:#e25600; text-decoration:underline;}
}


// Lazy load image
@keyframes fadeIn {
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}
.fadeIn {
	animation: fadeIn ease-in 500ms;
	opacity: 0;
	animation-fill-mode:forwards;
}



// IMG + figure
// ------------
img,
figure {
	@include breakpoint(small only) {
		&.alignleft,
		&.aligncenter,
		&.alignright {
			display: block;
			margin: 0 auto 1rem;
			text-align: center;
		}
	}
	@include breakpoint(medium) {
		&.alignleft {
			float: left;
			margin: 0 1rem 1rem 0;
		}
		&.alignright {
			float: right;
			margin: 0 0 1rem 1rem;
			text-align: right;

		}
		&.aligncenter {
			display: block;
			margin: 0 auto 1rem;
			text-align: center;
		}
	}
}

// Figure
// ------------
figure {
	padding: 0;
	margin: 0 0 1rem;
	display: inline-block;
	max-width: 100%;
	img {
		&.alignleft,
		&.alignright,
		&.aligncenter {
			margin: 0;
			float: none;
		}
	}
	figcaption {
		display: block;
		font-size: 1rem;
		font-style: italic;
		line-height: 1.2;
		padding-top: 8px;
		color: scale-color($body-font-color, $lightness: 30%)
	}
}

//------------------------
// TYPOGRAPHY
//------------------------
a, .transition-all {transition: all 250ms ease-in-out;}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	color: $black;
	font-family: $header-font-family;
	font-weight: $header-font-weight;
	font-style: $header-font-style;
}

// Heading styles
@each $size, $headers in $header-styles {
  @include breakpoint($size) {
    @each $header, $header-defs in $headers {
      $font-size-temp: 1rem;
      $line-height-base: 1rem * $header-lineheight;
      $margin-top: 0;
      $margin-bottom: unitless-calc($header-margin-bottom);
      #{$header},
      .#{$header} {

        @if map-has-key($header-defs, font-size) {
          $font-size-temp: rem-calc(map-get($header-defs, font-size));
          font-size: $font-size-temp;
        } @else if map-has-key($header-defs, fs) {
          $font-size-temp: rem-calc(map-get($header-defs, fs));
          font-size: $font-size-temp;
        } @else if $size == $-zf-zero-breakpoint {
          font-size: $font-size-temp;
        }

        @if map-has-key($header-defs, line-height) {
          line-height: unitless-calc(map-get($header-defs, line-height), $font-size-temp);
        } @else if map-has-key($header-defs, lh) {
          line-height: unitless-calc(map-get($header-defs, lh), $font-size-temp);
        } @else if $size == $-zf-zero-breakpoint {
          line-height: unitless-calc($header-lineheight, $font-size-temp);
        } @else {
        	line-height: ceil($font-size-temp / $line-height-base + rem-calc(10));
        }

			$margin-bottom: $header-margin-bottom;
        @if map-has-key($header-defs, margin-top) {
          $margin-top: map-get($header-defs, margin-top);
        } @else if map-has-key($header-defs, mt) {
          $margin-top: map-get($header-defs, mt);
        } @else if $size == $-zf-zero-breakpoint {
          $margin-top: 0;
        }
        margin-top: $line-height-base * $margin-top;


        @if map-has-key($header-defs, margin-bottom) {
          $margin-bottom: map-get($header-defs, margin-bottom);

        } @else if map-has-key($header-defs, mb) {
          $margin-bottom: map-get($header-defs, mb);
        } @else if $size == $-zf-zero-breakpoint {
          $margin-bottom: $header-margin-bottom;
        }

        margin-bottom: $line-height-base * unitless-calc($margin-bottom);
      }
    }
  }
}

small{
	color: $secondary-color;
}

h1, h2, h3, h4, h5, h6 {
	small {
		display: block;
		line-height: 1.2 !important;
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 10px;
		letter-spacing: 1px;
		font-weight: 400;

		a {
			color: $header-small-font-color;
		}
	}
}

h2, .h2{
	margin-top: 2.5rem;

	@include breakpoint(medium down) {
		margin-bottom: 1.125rem;
	}
}

h3, .h3{
	margin-bottom: 1.125rem;
	margin-top: 2.1rem;
}

h4, .h4{
	margin-bottom: 1rem;
	margin-top: 1.6rem;
}

h5, .h5{
	margin-bottom: 1rem;
	margin-top: 1.5rem;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
	@include clearfix;

  &:after {
    position: relative;
    width: 55px;
    border-bottom: 1px solid $fourth-color;
    margin: 10px auto 0;

		@include breakpoint(medium) {
			margin: 15px auto 0;
		}
  }

	&.text-left, .wysiwyg &{
		&:after{
			margin-left: 0;
		}
	}

	&.text-right{
		&:after{
			margin-right: 0;
		}
	}

	@include breakpoint(medium) {
		&.medium-text-right {
			&:after{
				margin-right: 0;
				margin-left: auto;
			}
		}

		&.medium-text-left {
			&:after{
				margin-left: 0;
				margin-right: auto;
			}
		}
	}

	@include breakpoint(large) {
		&.large-text-right {
			&:after{
				margin-right: 0;
				margin-left: auto;
			}
		}

		&.large-text-left {
			&:after{
				margin-left: 0;
				margin-right: auto;
			}
		}
	}
}

h4, .h4, h5, .h5{
	&:after{
		margin: 10px auto 0;
	}
}

ul, ol {
	margin-left: $list-side-margin;
	li {padding-bottom: 3px;}
}

p + ul,
p + ol {
	margin-top: -($paragraph-margin-bottom / 2);
}

.wysiwyg{
	&.page-content__body{
		*:first-child{
			margin-top: 0;
		}
	}

	@include breakpoint(medium) {
		*:first-child{
			margin-top: 0;
		}
	}

	a{
		text-decoration: underline;

		&:hover{
			text-decoration: none;
		}
	}

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6{
		&.text-center{
			&:after{
				margin-left: auto;
			}
		}
	}

	table{
		margin-bottom: 2rem;
	}
}

table {
  thead {
    border-bottom: 2px solid $medium-gray;
    th {
      text-align: center;
    }
  }
  td, th {
    border: 1px solid $medium-gray;
    text-align: left;
  }
}

button, a{
	outline: none;
	-webkit-appearance: none;
}

.button{
	font-weight: 500;
	padding: 10px 20px;
	min-width: 130px;
	text-align: center;
	border: 1px solid $fourth-color;
	line-height: rem-calc(20);
	text-decoration: none !important;
	transition: all 250ms ease;

	&:hover{
		background-color: $white;
		color: $primary-color;
	}

	&.secondary{
		&:hover{
			background-color: $white;
			color: $black;
		}
	}

	&.readmore, &.return{
		position: relative;
		background-color: transparent;
		padding: 0;
		@include icon(after, 'arrow-right');
		border: none;
		min-width: 0;
		text-align: left;
		color: $black;

		&:after{
			color: $fourth-color;
			font-size: rem-calc(18);
			margin-left: 10px;
			vertical-align: sub;
			transition: all 250ms ease;
		}

		&:hover{
			opacity: 0.8;

			&:after{
				margin-left: 20px;
			}
		}
	}

	&.return{
		@include icon(before, 'arrow-left');

		&:before{
			color: $fourth-color;
			font-size: rem-calc(18);
			margin-right: 10px;
			vertical-align: sub;
			transition: all 250ms ease;
		}

		&:after{
			display: none;
		}

		&:hover{
			&:before{
				margin-right: 20px;
			}
		}
	}
}

form{
	margin-top: 30px;
	background-color: rgba($tertiary-color, 0.2);
	padding: 20px 15px;
	position: relative;

	@include breakpoint(medium) {
		padding: 30px;
	}

	&:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("#{$imagesPath}/logotype-dark.svg") no-repeat 140% -30px;
    background-size: 85% auto;
    opacity: .15;
		z-index: -1;

    @include breakpoint(small only) {
      background-position: 200% -20px;
    }

    @include breakpoint(medium only) {
      background-size: 60% auto;
      background-position: 110% -20px;
    }
  }

	.form-mention{
		font-size: rem-calc(13);
		font-style: italic;
		line-height: 1.5;

		&.bottom{
			margin-top: 35px;
			margin-bottom: 0;
		}
	}

	label{
		span{
			color: $alert-color;
		}
	}

	textarea{
    height: 150px;
    resize: none;
  }

	.form-submit-grid{
		align-items: center;
	}

	.submit-cell{
		text-align: right;
	}

	.submit{
		float: none !important;
		margin-bottom: 0;
		width: auto !important;
	}
}

.text-upper {text-transform: uppercase;}

// variante de couleurs de texte
@if $include-html-text-color-classes == true {
	@each $name, $color in $foundation-palette {
        &.text-#{$name}-color {
           	color: $color;
        }
    }
}
// variante de couleurs de texte
@if $include-html-bg-color-classes == true {
	@each $name, $color in $foundation-palette {
        &.bg-#{$name}-color {
           	background-color: $color;
        }
    }
}

// Texte large
.lead {
	$lead-size: $global-font-size;
	@if $global-font-size == 100% {
		$lead-size: 16px;
	}

	&, p, ul, ol {
		font-size: rem-calc($lead-size + 2);
	}
}


// message (alert-box)
.callout {
	ul,p {
		&:last-child {margin-bottom: 0;}
	}
}

// logo rainbow
#rainbow {
	background-image: url('#{$imagesPath}/signature-rainbow-light.svg');
	background-size: 85px 46px;
	height: 23px;
	width: 85px;
	background-position: left top;
	background-repeat: no-repeat;
	display: block;
	margin: 5px auto;
	text-indent: -9000em;
	&:hover {
		background-position: left bottom;
	}
	@include breakpoint(medium){
		margin: 0 0 0 auto;
	}
}
